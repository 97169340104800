import axios from "axios";
import { ApiUrls, axiosConfig } from "../redux/apiUrls";

export const updateSubscriptionsByID = (userId, data) => {
    try {
        axios.put(ApiUrls.ACTIVATE_SUBSCRIPTION + userId, { confirmed: data }, axiosConfig());
    } catch (e) {
        console.log(e);
    }
};

export const saveOrUpdateSubscription = async (saveData) => {
    try {
        let data = await axios.post(ApiUrls.ADD_OR_UPDATE_SUBSCRIPTIONS, saveData, axiosConfig());
        return {
            success: true,
            status: 200,
            data: data?.data?.data || {}
        }
    } catch (error) {
        return {
            success: false,
            msg: "Some error occured while saving subscription details" 
        };
    }
}
