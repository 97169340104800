import axios from "axios";
import { ApiUrls, axiosConfig } from "../redux/apiUrls";
import { createUserApi } from "../redux/Users/api";
import {uploadUserImage,getImagePath,uploadUsersVideo,getVideoPath} from "../redux/Accounts/api"

export const updateUserStatus = (userId, status) => {
    try {
        axios.put(ApiUrls.UPDATE_USER_MODEL_STATUS + "/" + userId, { user_status: status}, axiosConfig())
    } catch (error) {
        console.error(error);
    }
};

/**
 * 
 * @param {integer} userId 
 * @param {string} value 
 * @param {string} fieldName 
 * Comment: Update any specific user field based on user id
 */
export const updateSpecificUserField = (userId, value, fieldName) => {
    try {
        axios.put(ApiUrls.UPDATE_USER_SPECIFIC_FIELD + "/" + userId, { value: value, fieldName: fieldName}, axiosConfig());
    } catch (error) {
        console.error(error);
    }
}

export const createNewUser = async(user) => {
    try {
        const data = await createUserApi(user);
        return {
            success: true,
            status: 200,
            data: data?.data?.data || {}
        }
    } catch (error) {
        return {
            success: false,
            msg: error?.response?.data?.msg || "Some internal error occured"
        };
    }
};

export const saveOrUpdateUserDetails = async (saveData) => {
    try {
        let data = await axios.post(ApiUrls.ADD_OR_UPDATE_USERDETAILS, saveData, axiosConfig());
        return {
            success: true,
            status: 200,
            data: data?.data?.data || {}
        }
    } catch (error) {
        return {
            success: false,
            msg: "Some error occured while saving subscription details"
        };
    }
}

export const uploadImage = async (items) => {
    try {
      const data = await uploadUserImage(items);
      return {
        success: true,
        status: 200,
        data: data
      }
    } catch (error) {
        return {
            success: false,
            msg: error?.response?.data?.msg || "Some internal error occurred"
        }
    }
};

export const getUserImage = async (id) => {
  try {
    const data = await getImagePath(id);
    return {
      success: true,
      data: data?.data,
    };
  } catch (error) {
    return {
      success: false,
      msg: error?.response?.data?.msg || "Some internal error occurred",
    };
  }
};

/**
 * 
 * @param {integer} id 
 * @param {object} data
 * Comment: Delete user image by id
 */
export const deleteUserImage = async (id, imageData) => {
    try {
			let data = await axios.post(ApiUrls.DELETE_USER_IMAGE + "/" + id, { userId: imageData.user_id, image: imageData.title }, axiosConfig());
			return {
				success: true,
				data: data?.data,
			};
		} catch (error) {
			return {
				success: false,
				msg: error?.response?.data?.msg || "Some internal error occurred",
			};
		}
}
/**
 * 
 * @param {object} item 
 * Comment: Upload user video  
 */

export const uploadVideo = async (item) => {
    try {
        const data = await uploadUsersVideo(item);
        return {
            success: true,
            status: 200,
            data: data?.data?.data || {}
        }
    } catch (error) {
        return {
            success: false,
            msg: error?.response?.data?.msg || "Some internal error occured"
        };
    }
}

/**
 * 
 * @param {integer} id 
 * Comment: Get user video list based on user id
 */
export const getUsersVideos = async (id) => {
    try {
        const data = await getVideoPath(id)
        return {
            success: true,
            data: data?.data,
          };
    } catch (error) {
        return {
            success: false,
            msg: error?.response?.data?.msg || "Some internal error occurred",
          };
    }
}

/**
 * 
 * @param {integer} id 
 * @param {object} videoData 
 * Comment: Delete user video based on Id
 */
export const deleteUserVideo = async (id, videoData) => {
  try {
    const data = await axios.post(
      ApiUrls.DELETE_USER_VIDEO + "/" + id,
      {
        user_Id: videoData.user_id,
        thumbnail: videoData.video_thumbnail,
        video_Url: videoData.video_url,
      },
      axiosConfig()
    );
    return {
      success: true,
      data: data?.data,
    };
  } catch (error) {
    return {
      success: false,
      msg: error?.response?.data?.msg || "Some internal error occurred",
    };
  }
};