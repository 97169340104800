import axios from "axios";
import { ApiUrls, axiosConfig } from "../redux/apiUrls";

/**
 * @param: null
 * @returns: Error or success response
 * Comment: Get list of project types
 */
export const getProjectTypesList = async () => {
	try {
		const data = await axios.get(ApiUrls.GET_FILTERS + "/project_type", axiosConfig());
		return {
			status: true,
			data: data?.data?.data
		}
	} catch (error) {
		return {
			status: false,
			errorMsg: error?.response?.data?.msg
		}
	}
};

/**
 * @param: {integer} id
 * @returns: Error or success response
 * Comment: Delete project based on Id
 */
export const deleteProjectById = async (id) => {
  try {
    const data = await axios.delete(ApiUrls.DELETE_PROJECT_BY_ID + '/' + id, axiosConfig());
		return {
			status: true,
			data: data?.data?.data
		}
  } catch (error) {
    return {
			status: false,
			errorMsg: error?.response?.data?.msg
		}
  }
};

/**
 * 
 * @param {object} item 
 * @returns Error or success response
 * Comment: Create user project
 */
export const createProject = async (postData) => {
	try {
		const data = await axios.post(ApiUrls.CREATE_USER_PROJECT,
			postData, axiosConfig());
		return {
			status: true,
			data: data?.data?.data
		}
	} catch (error) {
		return {
			status: false,
			errorMsg: error?.response?.data?.msg
		}
	}
}

/**
 * 
 * @param {integer} id 
 * @returns Error or success response
 * Comment: Get project by id
 */
export const getProjectById = async (id, requestType) => {
	try {
		const data = await  axios.get(`${ApiUrls.GET_PROJEECT_BY_ID}/${id}?requestType=${requestType}`, axiosConfig());
		return {
			status: true,
			data: data?.data
		}
	} catch (error) {
		return {
			status: false,
			errorMsg: error?.response?.data?.msg
		}
	}
}

/**
 * 
 * @param {integer} id 
 * @param {object} updateData 
 * @returns Error or success response
 * Comment: Update Project by Id
 */
export const updateProjectByTd = async (id, updateData) => {
	try {
		const data = await axios.put(ApiUrls.UPDATE_USER_PROJECT + '/' + id, updateData, axiosConfig())
		return {
			status: true,
			data: data?.data
		}
	} catch (error) {
		return {
			status: false,
			errorMsg: error?.response?.data?.msg
		}
	}
}

/**
 * 
 * @param {integer} id 
 * @returns Error or success response
 * Comment: Get project dashboard by project id
 */
export const getProjectDashboardById = async (id) => {
	try {
		const data = await axios.get(ApiUrls.GET_PROJECT_DASHBOARD_BY_ID + '/' + id, axiosConfig());
		return {
			status: true,
			data: data?.data
		}
	} catch (error) {
		return {
			status: false,
			errorMsg: error?.response?.data?.msg
		}
	}
}

/**
 * 
 * @returns Error or success response
 * Comment: Get saved Pdf
 */
export const getSavedPdf = async (projectId, episodeId) => {
	try {
		const data = await axios.get(ApiUrls.GET_DOWNLOAD_PDF + '/' + projectId + '/' + episodeId, axiosConfig());
		return {
			status: true,
			data: data?.data
		}
	} catch (error) {
		return {
			status: false,
			errorMsg: error?.response?.data?.msg
		}
	}
};

/**
 * @param: {integer} userId
 * @returns: Error or success response
 * Comment: Delete project based on UserId
 */
export const deleteAllProjectByUserId = async (userId) => {
  try {
    const data = await axios.delete(ApiUrls.DELETE_ALL_PROJECT_BY_USER_ID + '/' + userId, axiosConfig());
		return {
			status: true,
			data: data?.data?.data
		}
  } catch (error) {
    return {
			status: false,
			errorMsg: error?.response?.data?.msg
		}
  }
};

/**
 * @param: {integer} projectId
 * @param: {integer} projectStatus
 * @returns: Error or success response
 * Comment: Update project status by id
 */
export const updateProjectStatusById = async (projectId, projectStatus) => {
	try {
	  const data = await axios.put(ApiUrls.UPDATE_PROJECT_STATUS_BY_ID + '/' + projectId, projectStatus, axiosConfig());
		  return {
			  status: true,
			  data: data?.data?.data
		  }
	} catch (error) {
	  return {
			  status: false,
			  errorMsg: error?.response?.data?.msg
		  }
	}
};